<template>
  <form
    id="form"
    @submit.prevent="saveNewBookmark"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Neuen Link hinzufügen
        </p>
      </header>
      <section class="modal-card-body">
        <b-field label="Name">
          <b-input
            v-model="title"
            placeholder="Bezeichnung"
            required
            type="text"
          />
        </b-field>
        <b-field label="Link">
          <b-input
            v-model="url"
            placeholder="https://tld.de"
            required
            type="url"
          />
        </b-field>
        <b-field label="Kategorie">
          <b-select
            v-model="category"
            placeholder="In welche Kategorie soll gespeichert werden"
            required
          >
            <option
              v-for="option in categories"
              :key="option.i"
              :value="option.i"
            >
              {{ option.title }}
            </option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot card-footer">
        <button
          class="button card-footer-item"
          type="button"
          @click="cancel"
        >
          Lieber doch nicht
        </button>
        <button
          class="button is-primary card-footer-item"
          type="submit"
        >
          Speicher das!
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import store from '../store/store'

export default {
  name: 'NewBookmarkModal',
  props: {
    bookmarks: {
      type: Array,
      default: () => [],
    },
  },
  data ()
  {
    return {
      categories: store.getters.CATEGORIES,
      title: '',
      url: 'https://',
      category: 1,
    }
  },
  methods: {

    // Mutate the data in state manager
    saveNewBookmark: function (event) {
      this.$store.dispatch('SAVE_BOOKMARK', {
        title: this.title,
        url: this.url,
        category_id: this.category,
      })

      this.$parent.close()
    },
    cancel: function () {
      this.$parent.close()
    },
  },
}
</script>

<style scoped>

</style>
