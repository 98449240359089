const iconmap = {
  200: 'cloud_lightning_moon',
  201: 'cloud_lightning_moon',
  202: 'cloud_lightning_moon',
  210: 'cloud_lightning_moon',
  211: 'cloud_lightning_moon',
  212: 'cloud_lightning_moon',
  221: 'cloud_lightning_moon',
  230: 'cloud_lightning_moon',
  231: 'cloud_lightning_moon',
  232: 'cloud_lightning_moon',
  300: 'cloud_drizzle_moon',
  301: 'cloud_drizzle_moon',
  302: 'cloud_drizzle_moon',
  310: 'cloud_drizzle_moon',
  311: 'cloud_drizzle_moon',
  312: 'cloud_drizzle_moon',
  313: 'cloud_drizzle_moon',
  314: 'cloud_drizzle_moon',
  321: 'cloud_drizzle_moon',
  500: 'cloud_rain_moon',
  501: 'cloud_rain_moon',
  502: 'cloud_rain_moon',
  503: 'cloud_rain_moon',
  504: 'cloud_rain_moon',
  601: 'cloud_snow_alt_sun',

  602: 'cloud_snow_alt_sun',
  611: 'cloud_hail_alt_sun',
  612: 'cloud_hail_alt_sun',
  615: 'cloud_hail_alt_sun',
  616: 'cloud_hail_alt_sun',
  620: 'cloud_snow_sun',
  621: 'cloud_snow_sun',
  622: 'cloud_snow_alt_sun',
  701: 'cloud_fog_sun',
  711: 'cloud_fog_alt_sun',
  721: 'cloud_fog_sun',
  731: 'tornado',
  741: 'cloud_fog_sun',
  751: 'cloud_fog_alt_sun',
  761: 'cloud_fog_alt_sun',
  762: 'cloud_fog_alt_sun',
  771: 'cloud_fog_alt_sun',
  781: 'tornado',
  800: 'sun',
  801: 'cloud_sun',
  802: 'cloud_sun',
  803: 'cloud_sun',
  804: 'cloud_sun',
}

export default iconmap
