<template>
  <form
    id="form"
    @submit.prevent="updateBookmark"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Link bearbeiten
        </p>
      </header>
      <section class="modal-card-body">
        <b-field label="Name">
          <b-input
            v-model="bookmark.title"
            placeholder="bookmark.title"
            required
            type="text"
          />
        </b-field>
        <b-field label="Link">
          <b-input
            v-model="bookmark.url"
            placeholder="https://webseite.de"
            required
            type="url"
          />
        </b-field>
        <b-field label="Kategorie">
          <b-select
            v-model="category"
            placeholder="In welche Kategorie soll gespeichert werden"
            required
          >
            <option value="">
              Wähle eine Kategorie
            </option>
            <option
              v-for="option in categories"
              :key="option.i"
              :selected="option.i === bookmark.category_id"
              :value="option.i"
            >
              {{ option.title }}
            </option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button card-footer-item"
          type="button"
          @click="cancel"
        >
          Lieber doch nicht
        </button>
        <button
          class="button is-primary"
          type="submit"
        >
          Speicher das!
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
import store from '../store/store'

export default {
  name: 'UpdateBookmarkModal',
  props: {
    linkid: {
      type: Number,
      default: 0,
    },
    catid: {
      type: Number,
      default: 0,
    },
  },
  data ()
  {
    return {
      bookmark: store.getters.BOOKMARKS_BY_ID(this.linkid)[0],
      category: this.catid,
      title: null,
      url: null,
    }
  },

  computed: {
    categories: function () {
      return store.getters.CATEGORIES
    },
  },

  methods: {

    updateBookmark: function (event) {
      // Send new bookmark to API
      this.$store.dispatch('UPDATE_BOOKMARK', {
        id: this.linkid,
        category_id: this.category,
        title: this.bookmark.title,
        url: this.bookmark.url,
      })

      this.$parent.close()
    },
    cancel: function () {
      this.$parent.close()
    },
  },
}
</script>

<style scoped>

</style>
