<template>
  <section>
    <Header/>
    <DraggableCategories/>
  </section>
</template>

<script>
import DraggableCategories from '@/components/DraggableCategories'
import Header from '@/components/Header'

export default {
  name: 'Startpage',
  components: {
    Header,
    DraggableCategories,
  },

}
</script>


