import firebase from 'firebase/app'
import 'firebase/auth'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyBL6_jAqTBKjJQl8fIjaWKTslqOtAaEp9A',
  authDomain: 'startpage-e7da2.firebaseapp.com',
  projectId: 'startpage-e7da2',
  storageBucket: 'startpage-e7da2.appspot.com',
  messagingSenderId: '290714722342',
  appId: '1:290714722342:web:33f5aba1ed3d9198db06e7',
}

firebase.initializeApp(firebaseConfig)

// utils
const auth = firebase.auth()

// export utils/refs
export {
  auth,
}
