<template>
  <div class="modal-card">
    <section class="modal-card-body has-background-dark has-text-light">
      <div class="gradients-container">
        <h2 class="is-uppercase has-text-weight-bold">
          Settings
        </h2>
        <div class="grid">
          <template v-for="gradient in gradients">
            <Palette
              :key="gradient.name"
              :direction="direction"
              :gradient="gradient"
            />
          </template>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import Palette from '@/components/Palette'
import allGradientsFromLib from '@/lib/gradients.json'

export default {
  name: 'SettingsModal',
  components: {
    Palette,
  },
  filters: {
    generateCss (colors)
    {
      return colors.join()
    },
  },
  data ()
  {
    return {
      gradients: allGradientsFromLib,
      direction: '45deg',
    }
  }
}
</script>

<style lang="scss" scoped>
.gradients {
  display: flex;
}

.gradients-container {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: .5rem;
  }
}
</style>
