<template>
  <div class="clock">
    {{ clock }}
  </div>
</template>

<script>
export default {
  name: 'Clock',
  data ()
  {
    return {
      time: new Date(),
    }
  },
  computed: {
    clock ()
    {
      // force two digits, see https://stackoverflow.com/a/23579692/3758369
      let h = ('0' + this.time.getHours()).slice(-2),
        m = ('0' + this.time.getMinutes()).slice(-2),
        s = ('0' + this.time.getSeconds()).slice(-2)
      return `${h}:${m}:${s}`
    },
  },
  created ()
  {
    setInterval(() => {
      this.time = new Date()
    }, 1000)
  },
}
</script>

<style scoped>
.clock {
  margin-bottom: 0;
  padding: .375em .75em;
  width: 90px;
  text-align: right;
}
</style>
