<template>
  <div class="modal-card">
    <section class="modal-card-body has-background-dark has-text-light">
      <h2 class="is-uppercase has-text-weight-bold">
        Crypto Price Index
      </h2>
      <div>{{ btc.asset_id_base }}: {{ btc.rate | currencydecimal }} {{ btc.asset_id_quote }}</div>
      <div>{{ eth.asset_id_base }}: {{ eth.rate | currencydecimal }} {{ eth.asset_id_quote }}</div>
    </section>
  </div>
</template>
<script>

import axios from 'axios'

export default {
  name: 'CryptoModal',
  filters: {
    currencydecimal (value)
    {
      return !value || value.toFixed(2)
    },
  },
  data ()
  {
    return {
      btc: {
        asset_id_base: '0',
        asset_id_quote: '0',
      },
      eth: {
        asset_id_base: '0',
        asset_id_quote: '0',
      },
    }
  },

  mounted ()
  {
    const config = {
      headers: {
        'X-CoinAPI-Key': process.env.VUE_APP_COINAPI_KEY,
      },
    }

    // Fetch Bitcoin price from coinapi.io
    axios.get('https://rest.coinapi.io/v1/exchangerate/BTC/EUR', config).then(response => this.btc = response.data)

    // Fetch Ethereum price from coinapi.io
    axios.get('https://rest.coinapi.io/v1/exchangerate/ETH/EUR', config).then(response => this.eth = response.data)
  },
}
</script>
