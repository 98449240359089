<template>
  <div
    id="app"
    :style="backgroundCss"
  >
    <router-view/>
  </div>
</template>

<script>
export default {

  props: {
    cssBackgroundColors: {
      type: String,
      default: '#811d1d, #ffffff',
    },
  },
  computed: {
    backgroundCss ()
    {
      return {
        'background': `linear-gradient(to right, ${this.$store.state.cssBackgroundColors})`,
      }
    },
  },
  mounted ()
  {
    //this.$buefy.loading.open()
  },
}
</script>
<style>

body, #app {
  min-height: 100vh;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

@media (prefers-color-scheme: dark) {
  body {
    //background: var(--bg-gradient); /* see https://uigradients.com/#Mirage */
    background-size: 150% 100%;
    animation: gradient 25s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
}
</style>
