import Vue from 'vue'
import App from './App'
import router from './lib/router'
import store from './store/store'
import { auth } from './lib/firebase'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowsAlt,
  faCaretDown,
  faCloud,
  faCoins,
  faEdit,
  faMinusCircle,
  faPlus,
  faPlusCircle,
  faSun,
  faTools,
  faTrash,
  faUserSecret,
  faEnvelope,
  faLock,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Buefy from 'buefy'


import './assets/custom.scss'
import './assets/webfonts/MyFontsWebfontsKit.css'

library.add(faArrowsAlt, faCaretDown, faEdit, faTrash, faPlus, faTools, faUserSecret, faMinusCircle,
  faPlusCircle, faSun, faCloud, faCoins, faLock, faEnvelope, faUser)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.use(Buefy)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser)
  {
    next('/login')
  } else
  {
    next()
  }
})

let app
auth.onAuthStateChanged(() => {
  if (!app)
  {
    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  }
})


