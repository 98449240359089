<template>
  <div
    :style="backgroundStyle"
    class="palette-gradient"
    @click.prevent="changeGradient(gradient)"
  >
    <p class="palette-name">
      {{ gradient.name }}
    </p>
  </div>
</template>

<script>

export default {
  name: 'PALETTE',
  props: ['gradient', 'direction', 'updateGradient'],
  computed: {
    backgroundStyle ()
    {
      const colors = [...this.gradient.colors]
      const { direction } = this
      return {
        background: `linear-gradient(${direction}, ${colors})`,
      }
    },
  },
  methods: {
    changeGradient (gradient)
    {
      this.$store.dispatch('SET_BACKGROUND_GRADIENT', {
        cssBackgroundColors: gradient.colors.join(),
      })

      this.$store.dispatch('CHECK_PHILIPS_HUE_LIGHTS').then((response) => {
        if (response && response.status === 200)
        {
          console.log('status', console.status)
          this.$store.dispatch('SET_PHILIPS_HUE_LIGHTS', { colours: gradient.colors })
        }
      })

      this.$parent.$parent.close()
    },
  },
}
</script>
<style lang="scss">
.palette {
  &-gradient {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
</style>
