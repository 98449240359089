<template>
  <ul v-if="allbookmarks.length > 0">
    <li
      v-for="bookmark in allbookmarks"
      :key="bookmark.id"
      class="row"
    >
      <a
        :href="bookmark.url"
      >{{ bookmark.title }}</a>
      <div class="is-pulled-right 	">
        <a
          :title="`Link ${ bookmark.title } bearbeiten`"
          @click="openUpdateBookmarkModal(bookmark.id, bookmark.category_id)"
        >
          <font-awesome-icon
            class="edit"
            icon="edit"
          />
        </a>
        <a
          :title="`Link ${ bookmark.title } löschen`"
          @click="openDeleteBookmarkModal(bookmark.id, bookmark.title)"
        >
          <font-awesome-icon
            class="trash"
            icon="trash"
          />
        </a>
      </div>
    </li>
  </ul>
  <p v-else>
    Keine Links gefunden
  </p>
</template>

<script>
import UpdateBookmarkModal from './UpdateBookmarkModal'
import store from '../store/store'

export default {
  name: 'Bookmarks',
  props: {
    category: {
      type: Number,
      default: null,
    },
  },
  computed: {
    allbookmarks ()
    {
      return store.getters.BOOKMARKS_BY_CAT_ID(this.$props.category)
    },
  },

  methods: {

    openUpdateBookmarkModal (linkId, catId)
    {
      this.$buefy.modal.open({
        parent: this,
        component: UpdateBookmarkModal,
        hasModalCard: true,
        props: {
          catid: catId,
          linkid: linkId,
        },
      })
    },

    openDeleteBookmarkModal (id, title)
    {
      if (!id) return

      this.$buefy.dialog.confirm({
        title: 'Link löschen',
        message: `Möchtest du wirklich den Link <strong>${title}</strong> löschen?`,
        confirmText: 'Link löschen',
        type: 'is-danger',
        onConfirm: () => this.$store.dispatch('DELETE_BOOKMARK', id),
      })
    },
  },
}
</script>

<style scoped>
.trash {
  margin-left: .5rem;
}

@media (min-width: 768px) {
  .row {
    border-bottom: 1px solid transparent;
  }

  .row:hover {
    border-bottom: 1px dashed #0000002e;
  }
}
</style>
