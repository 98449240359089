import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import firebase from 'firebase'
import router from '../lib/router'
import { huecolour } from '@/lib/huecolour'
import { NotificationProgrammatic as Notification } from 'buefy'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    categories: [],
    bookmarks: [],
    currentUnit: null,
    cssBackgroundColors: localStorage.cssBackgroundColors,
    isHueWorking: false,
    userProfile: {},
  },

  actions: {
    async LOGIN ({ dispatch }, form)
    {
      // sign user in
      await firebase.auth().
        signInWithEmailAndPassword(form.email, form.password).then(() => {
          router.push('/')
        }).catch(e => {
          throw new Error(e.message)
        })
    },

    async LOGIN_WITH_APPLE ()
    {
      const provider = new firebase.auth.OAuthProvider('apple.com')
      provider.setCustomParameters({
        locale: 'de',
      })
      firebase.auth().signInWithPopup(provider).then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        const credential = result.credential

        // The signed-in user info.
        const user = result.user

        // You can also get the Apple OAuth Access and ID Tokens.
        const accessToken = credential.accessToken
        const idToken = credential.idToken

        // ...
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.email
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential
        Notification.open(error)
        console.log(error)

        // ...
      })

    },

    async LOGOUT ()
    {
      firebase.auth().signOut().then(() => {
          router.push('/login')
        },
      )
    },

    // Get all categories without links
    LOAD_ALL_CATEGORIES: function ({ commit }) {
      return axios.get(process.env.VUE_APP_API_URL + '/categories').then((response) => {
        commit('MUTATE_CATEGORIES', { categories: response.data })
        return store.state.categories
      })
    },

    // Set all categories, used when sorting
    SAVE_ALL_CATEGORIES: ({ commit }, categories) => {
      axios({
        method: 'post', // @todo: use 'put'
        url: process.env.VUE_APP_API_URL + '/categories',
        data: categories,
      })
    },

    // Get bookmarks
    LOAD_ALL_BOOKMARKS: async ({ commit }) => {
      try
      {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/bookmarks')
        if (response.status === 200)
        {
          commit('SET_ALL_BOOKMARKS_STORE', { bookmarks: response.data })
        }
      } catch (error)
      {
        commit('ERROR', error)
      }

    },

    // Set new bookmark
    SAVE_BOOKMARK: async ({ commit }, bookmark) => {
      const response = await axios.post(process.env.VUE_APP_API_URL + '/bookmarks', bookmark)
      if (response.status === 200)
      {
        const newId = response.data
        commit('ADD_NEW_BOOKMARK_STORE', { bookmark, newId })
      }
    },

    // Set new bookmark
    UPDATE_BOOKMARK: async ({ commit }, bookmark) => {
      await axios.put(`${process.env.VUE_APP_API_URL}/bookmarks/${bookmark.id}`, bookmark)
    },

    // Delete bookmark
    DELETE_BOOKMARK: async ({ commit }, id) => {
      const response = await axios.delete(`${process.env.VUE_APP_API_URL}/bookmarks/${id}`)
      if (response.status === 200)
      {
        commit('DELETE_BOOKMARK_STORE', { id })
      }
    },

    // Set body background from uigradients
    SET_BACKGROUND_GRADIENT: async ({ commit }, cssBackgroundColors) => {
      localStorage.cssBackgroundColors = cssBackgroundColors.cssBackgroundColors
      commit('SET_BACKGROUND_GRADIENT_STORE', { cssBackgroundColors })
    },

    CHECK_PHILIPS_HUE_LIGHTS: async ({ commit }, params) => {
      const hueUrl = `${process.env.VUE_APP_HUE_IP}/api/${process.env.VUE_APP_HUE_USER}/lights`
      try
      {
        const response = await axios.get(hueUrl)
        console.log(response)
        return response
      } catch (error)
      {
        console.log(`HUE only in local env abailable: ${error}`)
      }
    },

    SET_PHILIPS_HUE_LIGHTS: async ({ commit }, params) => {

      const hueUrl = process.env.VUE_APP_HUE_IP + '/api/' + process.env.VUE_APP_HUE_USER +
        '/lights/1/state'

      const hueColourValue = params.colours.map((c) => {
        return huecolour(c)
      })

      const response = await axios.put(
        process.env.VUE_APP_HUE_IP + '/api/' + process.env.VUE_APP_HUE_USER + '/lights/3/state',
        {
          'on': true, 'xy': hueColourValue[0],
        },
      )
      const response2 = await axios.put(
        process.env.VUE_APP_HUE_IP + '/api/' + process.env.VUE_APP_HUE_USER + '/lights/2/state',
        {
          'on': true, 'xy': hueColourValue[2] || hueColourValue[0],
        },
      )

      const response3 = await axios.put(
        process.env.VUE_APP_HUE_IP + '/api/' + process.env.VUE_APP_HUE_USER + '/lights/1/state',
        {
          'on': true, 'xy': hueColourValue[1],
        },
      )
    },
  },

  mutations: {
    // Add new bookmark to store
    ADD_NEW_BOOKMARK_STORE: (state, { bookmark, newId }) => {
      bookmark.id = newId
      state.bookmarks.push(bookmark)
    },

    SET_ALL_BOOKMARKS_STORE: function (state, { bookmarks }) {
      state.bookmarks = bookmarks
    },

    DELETE_BOOKMARK_STORE: function (state, { id }) {
      const bookmarks = state.bookmarks
      state.bookmarks = bookmarks.filter(data => data.id !== id)
    },

    SET_BACKGROUND_GRADIENT_STORE: function (state, { cssBackgroundColors }) {
      state.cssBackgroundColors = cssBackgroundColors.cssBackgroundColors
    },

    ERROR: function (state, { error }) {
      state.error = error
    },

    // Map API data to fixed data layout in DraggableCategories
    MUTATE_CATEGORIES: function (state, { categories }) {
      state.categories = categories.map(data => {
        // Increase height of card if bookmarks are present, else height 3
        // I really don't know what unit it is ...
        const minLength = data.bookmarks ? data.bookmarks.length + 2 : 3

        return {
          x: data.column,
          y: data.order,
          // Card height should never be under 2 columns
          w: data.width >= 2 ? data.width : 2,
          h: data.height > minLength ? data.height : minLength,
          i: data.id,
          minW: 2,
          title: data.title,
          //bookmarks: data.bookmarks || []
        }
      })
    },
  },
  getters: {
    BACKGROUND_GRADIENT: (state) => {
      return state.cssBackgroundColors
    },
    BOOKMARKS_BY_CAT_ID: (state) => (id) => {
      return state.bookmarks.filter(a => a.category_id === id)
    },
    BOOKMARKS_BY_ID: (state) => (id) => {
      return state.bookmarks.filter(a => a.id === id)
    },
    BOOKMARKS: state => {
      return state.bookmarks
    },
    CATEGORY_BY_ID: (state) => (id) => {
      return state.categories.filter(a => a.id === id)
    },
    CATEGORIES: (state) => {
      return state.categories
    },
    ERROR: (state) => {
      return state.error
    },
  },
})

export default store
