<template>
  <b-navbar>
    <template
      slot="brand"
    >
      <b-navbar-item>
        <img
          alt="Logo CLICKPRESS"
          src="https://clickpress.de/assets/img/clickpress-logo.svg"
        >
      </b-navbar-item>
    </template>

    <template slot="end">
      <b-navbar-item
        href="#"
      >
        <button
          class="plus button"
          icon="plus"
          @click="openNewBookmarkModal"
        >
          <span class="icon">
            <font-awesome-icon
              class="plus"
              icon="plus"
            />
          </span>
          <span>Link hinzufügen</span>
        </button>
      </b-navbar-item>
      <section class="is-flex">
        <b-navbar-item
          href="#"
          @click="openSettingsModal"
        >
          <span class="icon">
            <font-awesome-icon
              class="tools"
              icon="tools"
            />
          </span>
        </b-navbar-item>
        <b-navbar-item
          href="#"
          @click="openWeatherModal"
        >
          <span class="icon">
            <font-awesome-icon
              class="sun"
              icon="sun"
            />
          </span>
        </b-navbar-item>
        <b-navbar-item
          href="#"
          @click="openCryptoModal"
        >
          <span class="icon">
            <font-awesome-icon
              class="coins"
              icon="coins"
            />
          </span>
        </b-navbar-item>
      </section>
      <b-navbar-dropdown>
        <b-navbar-item
          href="#"
          @click="openAboutModal"
        >
          About
        </b-navbar-item>
        <b-navbar-item
          href="#"
          @click="logout"
        >
          Logout
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-item tag="div">
        <div class="buttons">
          <Clock />
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import Clock from './Clock'
import NewBookmarkModal from './NewBookmarkModal'
import AboutModal from './AboutModal'
import CryptoModal from './CryptoModal'
import WeatherModal from './WeatherModal'
import SettingsModal from './SettingsModal'
import { mapGetters } from 'vuex'
import store from '../store/store'

export default {
  name: 'Header',
  components: {
    Clock,
  },
  methods: {
    ...mapGetters(['BOOKMARKS']),
    logout ()
    {
      this.$store.dispatch('LOGOUT')
    },
    openNewBookmarkModal ()
    {
      this.$buefy.modal.open({
        parent: this,
        component: NewBookmarkModal,
        hasModalCard: true,
        props: {
          bookmarks: store.state.bookmarks,
        },
      })
    },
    openAboutModal ()
    {
      this.$buefy.modal.open({
        parent: this,
        component: AboutModal,
        hasModalCard: true,
      })
    },
    openCryptoModal ()
    {
      this.$buefy.modal.open({
        parent: this,
        component: CryptoModal,
        hasModalCard: true,
      })
    },
    openSettingsModal ()
    {
      this.$buefy.modal.open({
        parent: this,
        component: SettingsModal,
        //fullScreen: true,
        hasModalCard: true,
      })
    },
    openWeatherModal ()
    {
      this.$buefy.modal.open({
        parent: this,
        component: WeatherModal,
        hasModalCard: true,
      })
    },
  },
}
</script>

<style lang="scss">
.navbar-item img {
  max-height: 2.25rem
}

.navbar-brand {
  .navbar-item {
    padding: .5rem .625rem;
  }
}

</style>
