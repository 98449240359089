<template>
  <div class="modal-card">
    <section class="modal-card-body has-background-dark has-text-light">
      <ul>
        <li
          v-for="day in weatherData"
          :key="day.dt"
        >
          <div
            v-if="day.temp"
            class="columns py-2 is-mobile"
          >
            <figure class="column is-2 image is-64x64">
              <img
                :alt="`${ day.weather[0].description }`"
                :src="`img/icons/weather/climacon-${ iconmap[day.weather[0].id] }.svg`"
                class="weather_icon"
              >
            </figure>
            <div class="column is-5">
              <span class="has-text-weight-bold">{{ day.dt | formatDate }}</span><br>
              {{ day.temp.eve | formatTemperature }}&deg; {{ day.weather[0].description }}
            </div>
            <div class="column has-text-weight-light is-size-7 is-family-code">
              Tag: {{ day.temp.day | formatTemperature }}&deg;<br>
              Nacht: {{ day.temp.night | formatTemperature }}&deg;<br>
              Morgens: {{ day.temp.morn | formatTemperature }}&deg;
            </div>
            <div class="column has-text-weight-light is-size-7 is-family-code">
              Min: {{ day.temp.min | formatTemperature }}&deg;<br>
              Max: {{ day.temp.max | formatTemperature }}&deg;
            </div>
          </div>
        </li>
      </ul>
      <b-loading
        v-model="isLoading"
        :can-cancel="true"
      />
    </section>
  </div>
</template>
<script>


import axios from 'axios'
import iconmap from '../lib/iconmap'

export default {
  name: 'WeatherModal',
  filters: {
    formatDate (value)
    {

      if (!value) return

      const newDate = new Date()
      newDate.setTime(value * 1000)

      return newDate.toLocaleDateString('de-DE', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
    formatTemperature (temperature)
    {
      return !temperature || Math.floor(temperature)
    },
  },
  data ()
  {
    return {
      isLoading: true,
      geoData: {
        country: 'Germany',
        lat: 1,
        lon: 1,
        name: 'cloudy',
        zip: 0,
      },
      weatherData: {
        day: {
          temp: null,
          night: null,
          morn: null,
          eve: null,
          weather: [
            {
              id: 1,
            }],
        },
      },
      iconmap: iconmap,
      response: {
        data: {
          daily: {},
        },
      },
    }
  },

  created ()
  {
    this.isLoading = true
  },

  mounted ()
  {
    this.getCoordsFromBrowser().then((data) => {
      this.geoData.lat = data.coords.latitude
      this.geoData.lon = data.coords.longitude
      this.getWeatherForecast()
    }).catch(() => {
      this.getCoordsFromApi().then(response => {
        this.geoData = response
        this.getWeatherForecast()
      })
    })
  },

  methods: {

    async getCoordsFromApi ()
    {
      // Get geocode
      // see: https://openweathermap.org/api/geocoding-api
      const geocodeApi = 'https://api.openweathermap.org/geo/1.0/zip'
      const geocodeParams = {
        params: {
          zip: '58640,DE',
          appid: process.env.VUE_APP_OPENWEATHER_KEY,
        },
      }
      try
      {
        const response = await axios.get(geocodeApi, geocodeParams)
        return response.data
      } catch (error)
      {
        console.error(error)
      }
    },

    getCurrentPosition ()
    {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })
    },

    async getCoordsFromBrowser ()
    {
      if ('geolocation' in navigator) return await this.getCurrentPosition()
    },

    getWeatherForecast ()
    {
      // Get weather data
      // see: https://openweathermap.org/api/one-call-api
      const openWeatherApi = 'https://api.openweathermap.org/data/2.5/onecall'
      const openWeatherParams = {
        params: {
          lat: this.geoData.lat,
          lon: this.geoData.lon,
          exclude: 'minutely,hourly',
          units: 'metric',
          lang: 'de',
          appid: process.env.VUE_APP_OPENWEATHER_KEY,
        },
      }

      // Send weather data request
      axios.get(openWeatherApi, openWeatherParams).then(response => {
        this.weatherData = response.data.daily
      })

      // disable spinner
      this.isLoading = false
    },
  },
}
</script>
