<template>
  <grid-layout
    :col-num="12"
    :is-draggable="draggable"
    :is-mirrored="false"
    :is-resizable="resizable"
    :layout.sync="categories"
    :margin="[10, 10]"
    :responsive="false"
    :row-height="30"
    :use-css-transforms="true"
    :vertical-compact="true"
    @layout-before-mount="layoutBeforeMountEvent"
    @layout-updated="layoutUpdatedEvent"
    @layout-ready="layoutReadyEvent"
  >
    <grid-item
      v-for="item in categories"
      :key="item.i"
      :class="{open : (openEl === item.i) && isMobile}"
      :h="item.h"
      :i="item.i"
      :w="item.w"
      :x="item.x"
      :y="item.y"
      class="card"
      drag-allow-from=".handle"
      @move="moveEvent"
      @moved="movedEvent"
    >
      <div
        class="card-header"
      >
        <p
          class="card-header-title"
          @click="openAccordion(item.i)"
        >
          {{ item.title }}
        </p>
        <p
          class="accordion-toggler card-header-icon"
          @click="openAccordion(item.i)"
        >
          <font-awesome-icon
            :type="{ 'is-success': isGreen }"
            icon="caret-down"
          />
        </p>
        <p class="handle card-header-icon">
          <font-awesome-icon
            :type="{ 'is-success': isGreen }"
            icon="arrows-alt"
          />
        </p>
      </div>
      <div class="card-content">
        <Bookmark
          :category="item.i"
        />
      </div>
    </grid-item>
  </grid-layout>
</template>


<script>
import VueGridLayout from 'vue-grid-layout'
import Bookmark from './Bookmark'

const { GridLayout, GridItem } = VueGridLayout

export default {
  components: {
    GridLayout,
    GridItem,
    Bookmark,
  },
  data ()
  {
    return {
      draggable: true,
      resizable: true,
      responsive: true,
      isGreen: false,
      isFullPage: true,
      isOpen: {},
      openEl: 0,
      isMobile: window.innerWidth < 768,
    }
  },
  computed: {
    categories: {
      get ()
      {
        return this.$store.state.categories
      },
      set (value)
      {
      },
    },
  },
  mounted ()
  {
    const loading = this.$buefy.loading.open()
    this.$store.dispatch('LOAD_ALL_BOOKMARKS')

    this.$store.dispatch('LOAD_ALL_CATEGORIES').then(() => {
      loading.close()
    }).catch(error => {
      this.$buefy.modal.open({ content: error })
      loading.close()
    })

    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    openAccordion (el)
    {
      if (!this.isMobile) return
      this.openEl = el
    },
    handleResize ()
    {
      this.isMobile = window.innerWidth < 768
    },

    layoutBeforeMountEvent ()
    {
    },
    layoutReadyEvent ()
    {
    },

    // After dragging, resizing, moving send updated data to API
    layoutUpdatedEvent (newLayout)
    {
      this.$store.dispatch('SAVE_ALL_CATEGORIES', [...newLayout])
    },

    movedEvent: function (i, newX, newY) {
      //console.log({i: i, x: newX, y: newY});
    },

    moveEvent ()
    {
      this.isGreen = true
    },
  },
}
</script>
<style lang="scss" scoped>
.handle {
  display: none;
}

.card {
  background-color: rgba(255, 255, 255, .75);
}

.vue-grid-layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  max-width: 100vw;
  padding: 1rem;
  box-sizing: border-box;
  height: auto;
}

.card {
  .card-content {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    transition: height, padding 300ms cubic-bezier(.09, 1.73, .86, .99)
  }

  &.open {
    .card-content {
      max-height: none;
      overflow: hidden;
      padding: 1rem;
    }
  }
}

@media (min-width: 768px) {
  .vue-grid-layout {
    grid-template-columns: repeat(3, 1fr);
  }
  .accordion-toggler {
    display: none;
  }
  .card {
    touch-action: none;

    .card-content {
      max-height: none;
      padding: 1rem;
      transition: none;
    }
  }
}

@media (min-width: 1024px) {
  .handle {
    display: block;
  }
  .vue-grid-layout {
    user-select: none;
  }
  .card-content {
    padding: .75rem;
  }
}

@media (max-width: 1024px) {
  .vue-grid-layout {
    height: auto !important;
  }
  .vue-grid-item {
    box-sizing: border-box;
    position: static !important;
    margin-right: 0;
    width: auto !important;
    height: auto !important;
    max-width: 100vw;
    transform: none !important;
  }
  .vue-resizable-handle {
    display: none;
  }
  .card-header {
    cursor: pointer;
  }
}
</style>

