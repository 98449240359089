<template>
  <div class="modal-card">
    <section class="modal-card-body has-background-dark has-text-light">
      <div class="columns">
        <div class="column">
          <p>
            <span class="has-text-weight-bold">CLICKPRESS Internetagentur</span><br>
            Büttner/Schulz-Lauterbach GbR<br>
            Galmeistraße 23<br>
            58636 Iserlohn
          </p>
        </div>
        <div class="column">
          <p>
            Tel <a href="tel://+49237154710531">02371 54710531</a>
          </p>
          <p>
            <a
              id="obf_contact"
              href="mailto:info@clickpress.de"
            >info@clickpress.de</a><br>
            <a
              href="https://clickpress.de"
              target="_blank"
            >clickpress.de</a>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AboutModal',
}
</script>

<style scoped>
a {
  color: white
}
</style>
